import styled from "styled-components";

const ButtonAnchor = styled.a`
  color: white;
  transition: color 0.4s ease;
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const IconButton = ({ label, link, icon, hoverColor }) => {
  return (
    <ButtonAnchor
      title={label}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      hoverColor={hoverColor}
    >
      {icon}
    </ButtonAnchor>
  );
};
export default IconButton;
