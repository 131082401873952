import {
  Facebook,
  EnvelopeAt,
  Instagram,
  Youtube,
} from "react-bootstrap-icons";

import logo from "./logo.png";
import { Contacts, Header, Logo, Wrapper, LinksWrapper } from "./App.styled";
import IconButton from "./components/IconButton";

function App() {
  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="GFAP" />
        <LinksWrapper>
          <IconButton
            icon={<Facebook />}
            label="facebook"
            link="https://www.facebook.com/gfarquitecturaporto"
            hoverColor="#1877F2"
          />
          <IconButton
            icon={<Instagram />}
            label="instagram"
            link="https://www.instagram.com/gfadosarquitectura/"
            hoverColor="#c13584"
          />
          <IconButton
            icon={<Youtube />}
            label="youtube"
            link="https://www.youtube.com/@GFADOSARQUITECTURA"
            hoverColor="#FF0000"
          />
          <IconButton
            icon={<EnvelopeAt />}
            label="email"
            link="mailto:gfarquitecturaup@gmail.com"
            hoverColor="grey"
          />
        </LinksWrapper>

        <Contacts>
          <a href="tel:+351912867359">(+351) 912 867 359</a>
          <br />
          <br />
          Via Panorâmica 215
          <br />
          Porto, Portugal, 4150-140
        </Contacts>
      </Header>
    </Wrapper>
  );
}

export default App;
