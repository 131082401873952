import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
`;

export const Header = styled.header`
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 24px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

export const Contacts = styled.p`
  line-height: 1.4;
  color: white;
  a {
    color: white;
    transition: color 0.4s ease;
    &:hover {
      color: grey;
    }
  }
`;
